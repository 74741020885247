import moment from 'moment';
import { DEFAULT_PRODUCTS, DUMMY_MODULES } from '../constants/constants';

import {
  User,
  Client,
  ClientLocation,
  Product,
  ProductPrice,
  KaufDaItemSettings,
  SocialMediaItemSettings,
  SocialMediaTargetGroup,
  AdditionalOptionSettings,
  DistributionAppointment,
  PriceResult,
  LocationPrice,
  TotalPrice,
  ClientLayout,
  Module,
  Area,
} from '../@types/Data.d';

import { priceString } from './convertUtil';

/**
 * Extract a client module from a server response
 *
 * @param responseModule
 * @returns
 */
export const extractClientModule = (responseModule: any): Module => {
  const { title, enabled, id, config, type } = responseModule;

  return { id, title, enabled, config, type } as Module;
};

/**
 * Extract an array of client modules from a server response
 *
 * @param responseModules
 * @returns
 */
export const extractClientModules = (responseModules: any): Module[] =>
  responseModules.map((responseModule: any) =>
    extractClientModule(responseModule)
  );

/**
 * Extract a distribution appointment from a server response
 *
 * @param responseDistributionAppointment
 * @returns
 */
export const extractDistributionAppointment = (
  responseDistributionAppointment: any
): DistributionAppointment => {
  const { id, name, date, type } = responseDistributionAppointment;

  const cDate = moment(date);

  return { id, name, date: cDate, type } as DistributionAppointment;
};

/**
 * Extract an array of distribution appointments from a server response
 *
 * @param responseDistributionAppointments
 * @returns
 */
export const extractDistributionAppointments = (
  responseDistributionAppointments: any[]
): DistributionAppointment[] =>
  responseDistributionAppointments.map((responseDistributionAppointment) =>
    extractDistributionAppointment(responseDistributionAppointment)
  );

/**
 * Extract an additional options from a server response
 *
 * @param responseAdditionalOption
 * @returns
 */
export const extractAdditionalOption = (
  responseAdditionalOption: any
): AdditionalOptionSettings => {
  const {
    data,
    enabled,
    id,
    platform,
    mode,
    price,
    type,
  } = responseAdditionalOption;

  let optionsData;

  try {
    optionsData = JSON.parse(data);
  } catch (e) {
    optionsData = {};
  }

  if (type === 'KAUFDA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultHomepage: optionsData.homepage ?? optionsData.defaultHomepage,
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionHomepages: optionsData.presetSelectionHomepages ?? [],
      sortProperty: 'type',
    } as KaufDaItemSettings;
  if (type === 'SOCIALMEDIA')
    return {
      mode,
      type,
      enabled,
      id,
      price,
      platform,
      defaultRange: optionsData.range ?? optionsData.defaultRange,
      defaultDuration: optionsData.duration ?? optionsData.defaultDuration,
      defaultBudget: optionsData.budget ?? optionsData.defaultBudget,
      defaultTargetGroups: (optionsData.defaultTargetGroups?.map(
        (group: any) =>
          ({ id: group.id, name: group.name } as SocialMediaTargetGroup)
      ) ?? []) as SocialMediaTargetGroup[],
      presetSelectionRanges: optionsData.presetSelectionRanges ?? [],
      presetSelectionDurations: optionsData.presetSelectionDurations ?? [],
      presetSelectionBudgets: optionsData.presetSelectionBudgets ?? [],
      presetSelectionTargetGroups:
        optionsData.presetSelectionTargetGroups ?? [],
      sortProperty: 'platform',
    } as SocialMediaItemSettings;

  return { id, type } as AdditionalOptionSettings;
};

/**
 * Extract an array of additional options from a server response
 *
 * @param responseAdditionalOptions
 * @returns
 */
export const extractAdditionalOptions = (
  responseAdditionalOptions: any
): AdditionalOptionSettings[] =>
  (responseAdditionalOptions?.map((responseAdditionalOption: any) =>
    extractAdditionalOption(responseAdditionalOption)
  ) ?? []) as AdditionalOptionSettings[];

/**
 * Extract a user from a server response
 *
 * @param userData
 * @returns
 */
export const parseUser = (userData: any): User => {
  const { email, forename, id, salutation, surname, avatar } = userData;
  return {
    email,
    prename: forename,
    id,
    salutation,
    lastname: surname,
    avatar,
    // avatar: 'user_placeholder',
  } as User;
};

/**
 * Extract a client from a server response
 *
 * @param clientData
 * @returns
 */
export const parseClient = (clientData: any): Client => {
  const { id, name, transmissionType, weekparts, showPrice, uuid } = clientData;

  return {
    id,
    name,
    transmissionType,
    weekparts: weekparts.sort(),
    showPrice,
    uuid,
  } as Client;
};

/**
 * Extract an array clients from a server response
 *
 * @param clientsData
 * @returns
 */
export const parseClients = (clientsData: any): Client[] =>
  clientsData
    .map((clientData: any) => parseClient(clientData))
    .sort((a: Client, b: Client) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      return 0;
    });

/**
 * Extract a client locations from a server response
 *
 * @param param0
 * @returns
 */
export const extractClientLocation = ({
  addressName,
  city,
  colorSelectedFill,
  housenumber,
  id,
  // TODO img,
  // TODO lat,
  // TODO lon,
  name,
  number,
  postcode,
  street,
  billingDefault,
}: any): ClientLocation =>
  ({
    addressName,
    city,
    colorSelectedFill,
    housenumber,
    id,
    name,
    number,
    postcode,
    selected: false,
    street,
    show: true,
    billingDefault: billingDefault ?? false,
    areas: [] as Area[],
  } as ClientLocation);

/**
 * Extract an array client locations from a server response
 *
 * @param responseClientLocations
 * @returns
 */
export const extractClientLocations = (
  responseClientLocations: any
): ClientLocation[] =>
  responseClientLocations
    .map((responseClientLocation: any) =>
      extractClientLocation(responseClientLocation)
    )
    .sort((a: ClientLocation, b: ClientLocation) => {
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
      return 0;
    });

/**
 * Extract a product from a server response
 *
 * @param param0
 * @returns
 */
export const extractProduct = ({
  id,
  name,
  printDocRequired,
  productPrices = [],
}: any): Product =>
  ({
    id,
    name,
    printDocRequired,
    productPrices: productPrices.map(
      (productPrice: any) =>
        ({
          id: productPrice.id,
          price: productPrice.price,
          quantityFrom: productPrice.quantityFrom,
          quantityTo: productPrice.quantityTo,
        } as ProductPrice)
    ),
  } as Product);

/**
 * Extract an array of products from a server response
 *
 * @param responseProducts
 * @returns
 */
export const extractProducts = (responseProducts: any): Product[] =>
  responseProducts
    .map((responseProduct: any) => extractProduct(responseProduct))
    .sort((a: Product, b: Product) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

/**
 * parse a full client from a server response
 *
 * @param param0
 * @returns
 */
export const parseFullClient = ({
  id,
  transmissionType,
  products,
  name,
  weekparts,
  showPrice,
  additionalOptions,
  distributionAppointments,
  distributionDateType,
  billingType,
  billCity,
  billHousenumber,
  billName,
  billPostcode,
  billStreet,
  uuid,
}: any): Client =>
  ({
    id,
    name,
    transmissionType,
    weekparts: weekparts.sort(),
    clientLocations: [],
    products:
      products.length > 0 ? extractProducts(products) : DEFAULT_PRODUCTS,
    showPrice,
    additionalOptions: extractAdditionalOptions(additionalOptions),
    distributionAppointments: extractDistributionAppointments(
      distributionAppointments
    ),
    distributionDateType,
    billingType,
    billingAddress: {
      city: billCity,
      housenumber: billHousenumber,
      name: billName,
      postcode: billPostcode,
      street: billStreet,
    },
    modules: extractClientModules(DUMMY_MODULES),
    uuid,
  } as Client);

/**
 * Extract the price of a client locations
 * current selection
 *
 * @param responsePrice
 * @returns
 */
export const extractLocationPrice = (responsePrice: any): LocationPrice => {
  const {
    id,
    totalDistribution,
    totalDistributionInclusiveVat,
    vat,
    totalDistributionPerThousand,
    totalKaufDa,
    circulation,
  } = responsePrice;

  return {
    id,
    total: priceString(totalDistribution),
    totalInclusiveVat: priceString(totalDistributionInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalDistributionPerThousand),
    totalKaufDa: priceString(totalKaufDa),
    circulation: +circulation,
  } as LocationPrice;
};

/**
 * Extract the  price response from the server
 *
 * @param responsePrice
 * @returns
 */
export const extractTotalPrice = (responsePrice: any): TotalPrice => {
  const {
    id,
    total,
    totalInclusiveVat,
    vat,
    totalPerThousand,
    subtotalDistributionPerThousand,
    subtotalDistribution,
    subtotalKaufDa,
    subtotalPrint,
    subtotalPrintPerThousand,
  } = responsePrice;

  return {
    id,
    total: priceString(total),
    totalInclusiveVat: priceString(totalInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalPerThousand),
    subtotalDistributionPerThousand: priceString(
      subtotalDistributionPerThousand
    ),
    subtotalDistribution: priceString(subtotalDistribution),
    subtotalKaufDa: priceString(subtotalKaufDa),
    subtotalPrint: priceString(subtotalPrint),
    subtotalPrintPerThousand: priceString(subtotalPrintPerThousand),
  } as TotalPrice;
};

/**
 * Extract the  price response from the server
 *
 * @param responsePrices
 * @returns
 */
export const extractPrices = (responsePrices: any): PriceResult => {
  const {
    total,
    totalInclusiveVat,
    vat,
    locations,
    subtotalDistribution,
    subtotalDistributionPerThousand,
    subtotalKaufDa,
  } = responsePrices;

  return {
    price: extractTotalPrice({
      total,
      totalInclusiveVat,
      vat,
      subtotalDistribution,
      subtotalDistributionPerThousand,
      subtotalKaufDa,
    }),
    clientLocationPrices: locations.map((location: any) =>
      extractLocationPrice(location)
    ),
  };
};

/**
 * Extract a client layout from a server response
 *
 * @param responseLayout
 * @returns
 */
export const extractClientLayout = (responseLayout: any): ClientLayout => {
  const { id, name, url, state, stateFileId } = responseLayout;

  return { id, name, url, state, stateFileId } as ClientLayout;
};

/**
 * Extract an array of client layouts from a server response
 *
 * @param responseLayouts
 * @returns
 */
export const extractClientLayouts = (responseLayouts: any[]): ClientLayout[] =>
  responseLayouts.map((responseLayout) => extractClientLayout(responseLayout));

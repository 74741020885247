import '../../styles/Dashboard.scss';

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

import {
  DashboardContainerProps,
  DashboardItemProps,
} from '../../@types/Dashboard.d';
import {
  DASHBOARD_HEADER_TEXT,
  DASHBOARD_LAYOUT,
  DASHBOARD_COMPLAINT,
  DASHBOARD_DISTRIBUTION,
} from '../../constants/labels';
import {
  ROUTER_PATH_COMPLAINT,
  ROUTER_PATH_DISTRIBUTION,
  ROUTER_PATH_HOME,
  ROUTER_PATH_LAYOUT,
} from '../../constants/constants';
import config from '../../config';

// import distributionSmall from '../../resources/img/icon/distributionSmall.png';
// import * as layoutIcon from `../../resources/img/icon/${config.layoutIconSmall}`;
import { navigateLayout } from '../../util/iFrameMessageUtil';

/**
 * A card item shown in the dashboard for navigation
 *
 * @param props
 * @returns
 */
const DashboardItem: React.FC<DashboardItemProps> = (
  props: DashboardItemProps
) => {
  const { title, icon, action } = props;

  /**
   * On click listener for the card
   *
   * @param event
   */
  const onClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    action();
  };

  /**
   * On key down listener for the card
   *
   * @param event
   */
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    action();
  };

  /**
   * Load the card icon
   *
   * @param iconId
   * @returns
   */
  const loadIcon = (iconId?: string): string =>
    // eslint-disable-next-line import/no-dynamic-require
    require(`../../resources/img/icon/${iconId}`).default as string;

  return (
    <div className="choice-container">
      <div
        className="choice-content"
        role="button"
        tabIndex={-1}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <div className="image-container">
          <img src={loadIcon(icon)} alt={title} />
        </div>
        <div className="title">{title}</div>
      </div>
    </div>
  );
};

/**
 * A constainer that represents the dashboard and shows a number
 * of card items for navigation
 *
 * @param props
 * @returns
 */
const DashboardContainer: React.FC<DashboardContainerProps> = (
  props: DashboardContainerProps
) => {
  const { history, modules } = props;

  const navigateAction = (target: string): void => {
    switch (target) {
      case ROUTER_PATH_LAYOUT:
        navigateLayout('/');
        history.push(ROUTER_PATH_LAYOUT);
        break;
      case ROUTER_PATH_DISTRIBUTION:
      case ROUTER_PATH_HOME:
      case ROUTER_PATH_COMPLAINT:
        history.push(target);
        break;
      default:
    }
  };

  return (
    <Row className="selection-container-parent">
      <Col lg={2} md={0} className="selection-spacer left" />
      <Col lg={8} md={12} className="selection-container">
        <Row className="no-gutters selection-header-container">
          <Col className="selection-header">{DASHBOARD_HEADER_TEXT}</Col>
        </Row>
        <Row className="no-gutters selection-item-container">
          <div className="selection-items">
            {modules?.find((module) => module.type === 'LAYOUTDESIGN')
              ?.enabled && (
              <DashboardItem
                title={DASHBOARD_LAYOUT}
                icon={config.layoutIconSmall}
                action={() => navigateAction(ROUTER_PATH_LAYOUT)}
              />
            )}
            <DashboardItem
              title={DASHBOARD_DISTRIBUTION}
              icon={config.distributionIconSmall}
              action={() => navigateAction(ROUTER_PATH_DISTRIBUTION)}
            />
            {config.complaintUrl &&
              modules?.find((module) => module.type === 'COMPLAINT')
                ?.enabled && (
                <DashboardItem
                  title={DASHBOARD_COMPLAINT}
                  icon={config.distributionIconSmall}
                  action={() => navigateAction(ROUTER_PATH_COMPLAINT)}
                />
              )}
          </div>
        </Row>
      </Col>
      <Col lg={2} md={0} className="selection-spacer right" />
    </Row>
  );
};

export default withRouter(DashboardContainer);

/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { Moment } from 'moment';

import { Form, Col } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';

import ContentContainer from './ContentContainer';
import StepWizardStepAbstract from '../../Common/StepWizard/StepWizardStepAbstract';

import {
  OFFER_MODAL_ACTION_TITLE,
  OFFER_MODAL_ACTION_ACTION_NAME_LABEL,
  OFFER_MODAL_ACTION_ACTION_NAME_PLACEHOLDER,
  OFFER_MODAL_ACTION_DISTRIBUTION_DATE_LABEL,
  OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_LABEL,
  OFFER_MODAL_ACTION_DISTRIBUTION_DATE_PLACEHOLDER,
  CALENDAR_WEEK_SHORT,
  OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_PLACEHOLDER,
  OFFER_MODAL_ACTION_DISTRIBUTION_DATE_INVALID,
  OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_INVALID,
} from '../../../constants/labels';
import { selectPickerTheme } from '../../../constants/constants';

import {
  ActionProps,
  ActionState,
  DistributionAppointmentDropOptionProps,
} from '../../../@types/OrderModal.d';
import { DistributionAppointment } from '../../../@types/Data.d';

/**
 * Select option for distribution appointment select
 *
 * @param props
 * @returns
 */
const DistributionAppointmentSelectOption: React.FC<DistributionAppointmentDropOptionProps> = (
  props: DistributionAppointmentDropOptionProps
) => {
  const { data } = props;

  return (
    <div className="distribution-appointment-dropdown-option">
      <div className="distribution-appointment-name custom-text-wrap">
        {`${data.name}`}
      </div>
      <small className="distribution-appointment-date">{`${
        data.type === 'WEEK'
          ? `${data.date.format(
              'WW'
            )}. ${CALENDAR_WEEK_SHORT} ${data.date.format('YYYY')}`
          : data.date.format('DD.MM.YYYY')
      }`}</small>
    </div>
  );
};

/**
 * Component to define the name and time of the distribution
 */
export default class Action extends StepWizardStepAbstract<
  ActionProps,
  ActionState
> {
  constructor(props: ActionProps) {
    super(props);

    // this.state = { formValidated: false };

    this.onChangeActionName = this.onChangeActionName.bind(this);
    this.onChangeDistributionAppointment = this.onChangeDistributionAppointment.bind(
      this
    );
    this.onChangeDistributionDate = this.onChangeDistributionDate.bind(this);
  }

  /**
   * On change listener for the distribution appointment select
   *
   * @param pDistributionAppointment
   */
  onChangeDistributionAppointment(pDistributionAppointment: any): void {
    const { changeDistributionAppointment } = this.props;

    if (pDistributionAppointment && pDistributionAppointment !== null)
      changeDistributionAppointment(
        pDistributionAppointment.data as DistributionAppointment
      );
  }

  /**
   * On change listner for the action name
   *
   * @param event
   */
  onChangeActionName(event: React.ChangeEvent<HTMLInputElement>): void {
    const { changeActionName } = this.props;

    changeActionName(event.currentTarget.value);
  }

  /**
   * On change listener for the distribution date picker
   *
   * @param pDistributionDate
   */
  onChangeDistributionDate(pDistributionDate: Moment | null): void {
    const { changeDistributionDate } = this.props;

    changeDistributionDate(pDistributionDate);
  }

  render(): JSX.Element {
    const { formValidated } = this.state;
    const {
      distributionDateType,
      isOffer,
      actionName,
      weekpart,
      distributionDate,
      datePickerFocused,
      distributionAppointments,
      distributionAppointment,
      isDayBlocked,
      isDayHighlighted,
      onDatePickerFocusChange,
    } = this.props;

    return (
      <ContentContainer title={OFFER_MODAL_ACTION_TITLE}>
        <Form
          ref={this.formRef}
          id="action-form"
          validated={formValidated}
          noValidate
        >
          {distributionDateType === 'FREE' && (
            <Form.Row>
              <Col xs={12} xl={6}>
                <Form.Group>
                  <Form.Label>
                    {OFFER_MODAL_ACTION_ACTION_NAME_LABEL}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="new-password"
                    type="text"
                    value={actionName}
                    onChange={this.onChangeActionName}
                    placeholder={OFFER_MODAL_ACTION_ACTION_NAME_PLACEHOLDER}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}
          <Form.Row>
            <Col xs={12} xl={6}>
              <Form.Group>
                <Form.Label>
                  {distributionDateType === 'FREE'
                    ? OFFER_MODAL_ACTION_DISTRIBUTION_DATE_LABEL
                    : OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_LABEL}
                </Form.Label>
                {distributionDateType === 'FREE' ? (
                  weekpart === 'BEST' ? (
                    <SingleDatePicker
                      date={distributionDate}
                      onDateChange={this.onChangeDistributionDate}
                      focused={datePickerFocused}
                      onFocusChange={({ focused }) =>
                        onDatePickerFocusChange(focused)
                      }
                      id="distribution-date-picker"
                      // openDirection="up"
                      placeholder={
                        OFFER_MODAL_ACTION_DISTRIBUTION_DATE_PLACEHOLDER
                      }
                      showClearDate
                      isDayBlocked={(day: any) => isDayBlocked(day)}
                      isDayHighlighted={(day: any) => isDayHighlighted(day)}
                      numberOfMonths={1}
                      // eslint-disable-next-line
                    displayFormat="WW. \K\a\l\e\n\d\e\r\w\o\c\h\e"
                      block
                    />
                  ) : (
                    <SingleDatePicker
                      date={distributionDate}
                      onDateChange={this.onChangeDistributionDate}
                      focused={datePickerFocused}
                      onFocusChange={({ focused }) =>
                        onDatePickerFocusChange(focused)
                      }
                      id="distribution-date-picker"
                      // openDirection="up"
                      placeholder={OFFER_MODAL_ACTION_DISTRIBUTION_DATE_LABEL}
                      showClearDate
                      isDayBlocked={(day: any) => isDayBlocked(day as Moment)}
                      isDayHighlighted={(day: any) =>
                        isDayHighlighted(day as Moment)
                      }
                      numberOfMonths={1}
                      displayFormat="dddd, DD.MM.YYYY"
                      block
                    />
                  )
                ) : (
                  <Select
                    // blurInputOnSelect
                    isSearchable={false}
                    options={distributionAppointments.map(
                      (pDistributionAppointment) => ({
                        value: pDistributionAppointment.date.unix(),
                        label: pDistributionAppointment.name,
                        data: pDistributionAppointment,
                      })
                    )}
                    value={
                      distributionAppointment
                        ? {
                            label: distributionAppointment.name ?? '',
                            value: distributionAppointment.date.unix(),
                            data: distributionAppointment,
                          }
                        : undefined
                    }
                    placeholder={
                      OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_PLACEHOLDER
                    }
                    onChange={this.onChangeDistributionAppointment}
                    theme={(theme: any) => selectPickerTheme(theme)}
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    formatOptionLabel={DistributionAppointmentSelectOption}
                    menuPortalTarget={document.body}
                    closeMenuOnScroll={false}
                  />
                )}
                <Form.Control
                  value={
                    distributionDate !== null
                      ? distributionDate.format('dd.MM.yyyy')
                      : ''
                  }
                  onChange={() => {}}
                  hidden
                  required={!isOffer || distributionDateType === 'TEMPLATE'}
                />
                <Form.Control.Feedback type="invalid">
                  {distributionDateType === 'FREE'
                    ? OFFER_MODAL_ACTION_DISTRIBUTION_DATE_INVALID
                    : OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </ContentContainer>
    );
  }
}

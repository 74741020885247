/* eslint-disable no-nested-ternary */
import '../styles/App.scss';
import '../styles/Modal.scss';

import 'moment/locale/de';

import React from 'react';

import { withCookies } from 'react-cookie';
import { head, find, forEach, remove } from 'lodash';
import { Moment } from 'moment';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import DistributionPlaningContainer from './DistributionPlaning/DistributionPlaningContainer';
import LoginModal from './Modal/LoginModal';
import StepSelectionContainer from './Common/Dashboard';
import LoadingOverlay from './Common/LoadingOverlay';
import HeaderContainer from './Common/HeaderContainer';
import LayoutContainer from './Layout/LayoutContainer';
import MainMenu from './Common/MainMenu/MainMenu';
import NotFound from './Common/NotFound';
import NavigationModal from './Modal/NavigationModal';
import ComplaintContainer from './Complaint/ComplaintContainer';

import {
  getClientLocationsSend,
  getAreasSend,
  getBas64FromFile,
} from '../util/convertUtil';
import ChangePasswordModal from './Modal/ChangePasswordModal';
import ResponseModal from './Modal/ResponseModal';
import ConfirmationModal from './Modal/ConfirmationModal';
import OrderModal from './Modal/OrderModal/OrderModal';

import {
  getTotalCirculationAreas,
  getTotalCirculationClientLocations,
} from '../util/areaUtil';
import { changeProduct, addOrder } from '../util/iFrameMessageUtil';
import {
  getLogin,
  putChangePassword,
  getClients,
  getClientData,
  postOrderRequest,
  // postOfferRequest,
  getTotalPrice,
  getAuthByToken,
  getClientLocations,
} from '../util/api';

import {
  COOKIE_USER_NAME,
  REQUEST_IDENTIFIER_GET_CLIENTS,
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_CHANGE_PASSWORD,
  REQUEST_IDENTIFIER_LOGIN,
  REQUEST_IDENTIFIER_SEND_OFFER_ORDER,
  REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS,
  DEFAULT_PRODUCTS,
  TRANSMISSION_TYPE_ORDER,
  ROUTER_PATH_DISTRIBUTION,
  ROUTER_PATH_HOME,
  ROUTER_PATH_LAYOUT,
  ROUTER_PATH_COMPLAINT,
} from '../constants/constants';
import {
  PARAM_PARENTORIGIN,
  PARAM_CLIENT_ID,
  PARAM_CLIENT_LOCATION_MODE,
  PARAM_EMAIL,
  PARAM_WEEKPART,
  PARAM_INIT_TARGET,
} from '../constants/network';
import config from '../config';
import {
  RESPONSE_MODAL_SUCCESS_TITLE,
  RESPONSE_MODAL_SUCCESS_CONTENT,
  RESPONSE_MODAL_FAILURE_TITLE,
  RESPONSE_MODAL_FAILURE_CONTENT,
  LOADING_PLEASE_WAIT,
  LOADING_PROCESS_REQUEST,
} from '../constants/labels';

import { AppProps, AppState } from '../@types/App.d';
import {
  User,
  Client,
  ClientLocation,
  Salutation,
  Product,
  ClientLocationSendFormat,
  RequestPayload,
  Price,
  AdditionalOptions,
  Weekpart,
  DateType,
  ExtraCopy,
  MasterFile,
  BillingType,
  PriceResult,
  TotalPrice,
  LayoutSelection,
  Area,
} from '../@types/Data.d';
import {
  FPMMessageType,
  FPMMessage,
  LocalityMessagePayload,
  AreaMessagePayload,
  HideClientLocationSelectionPayload,
  LayoutMessageType,
  LayoutMessage,
  LayoutMessageNavType,
  FinishActionNavType,
} from '../@types/MessageTypes.d';

/**
 * Root component of the app
 */
class App extends React.Component<AppProps, AppState> {
  confirmationModalRef = React.createRef<ConfirmationModal>();

  responseModalRef = React.createRef<ResponseModal>();

  constructor(props: AppProps) {
    super(props);

    const { cookies, token } = this.props;
    const { clientLocationMode, defaultWeekpart } = config;

    if (token) cookies.remove('user');

    this.state = {
      showLoginModal: false,
      showChangePasswordModal: false,
      showOrderModal: false,
      showConfirmationModal: false,
      showResponseModal: false,
      showNavigationModal: false,
      showMainMenu: false,
      isLoading: false,
      pendingRequests: [],
      user: cookies.get('user') ?? undefined,
      clientLocationMode: cookies.get('user') ?? clientLocationMode,
      weekpart: defaultWeekpart as Weekpart,
      totalCirculation: 0,
      mapIFrameURL: '',
      layoutIFrameURL: '',
      complaintIFrameURL: '',
      areas: [] as Area[],
      selectedClientLocations: [] as ClientLocation[],
      selectedProduct: DEFAULT_PRODUCTS[0],
    };

    this.submitOfferRequest = this.submitOfferRequest.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
    this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);

    this.findArea = this.findArea.bind(this);

    this.showLoginModal = this.showLoginModal.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.showOrderModal = this.showOrderModal.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.showResponseModal = this.showResponseModal.bind(this);
    this.showMainMenu = this.showMainMenu.bind(this);
    this.showNavigationModal = this.showNavigationModal.bind(this);

    this.submitLogin = this.submitLogin.bind(this);
    this.submitLoginViaToken = this.submitLoginViaToken.bind(this);
    this.setUser = this.setUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.changeSelectedClient = this.changeSelectedClient.bind(this);
    this.changeMapIFrameURL = this.changeMapIFrameURL.bind(this);
    this.changeLayoutIFrameURL = this.changeLayoutIFrameURL.bind(this);
    this.changeComplaintIFrameURL = this.changeComplaintIFrameURL.bind(this);

    this.onReceiveMessage = this.onReceiveMessage.bind(this);
    this.onReceiveFPMMessage = this.onReceiveFPMMessage.bind(this);
    this.onReceiveLayoutMessage = this.onReceiveLayoutMessage.bind(this);
    this.removeClientLocationFromSelection = this.removeClientLocationFromSelection.bind(
      this
    );
    this.resetAreaSelection = this.resetAreaSelection.bind(this);

    this.getTotalCirculation = this.getTotalCirculation.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.getClientLocations = this.getClientLocations.bind(this);

    this.setSelectedProduct = this.setSelectedProduct.bind(this);

    this.navigateTo = this.navigateTo.bind(this);
  }

  componentDidMount(): void {
    const { token } = this.props;
    const { user } = this.state;

    window.addEventListener('message', this.onReceiveMessage);
    this.changeMapIFrameURL();
    this.changeLayoutIFrameURL();

    if (config.complaintUrl) this.changeComplaintIFrameURL();

    if (token) this.submitLoginViaToken();
    if (user) this.getUserClients();
  }

  /**
   * The event listener for the iframe communication
   *
   * @param event
   */
  onReceiveMessage(event: MessageEvent): void {
    if (Object.values(FPMMessageType).includes(event.data.type))
      this.onReceiveFPMMessage(event);
    if (Object.values(LayoutMessageType).includes(event.data.type))
      this.onReceiveLayoutMessage(event);
  }

  /**
   * Processes messages from FPL
   *
   * @param event
   */
  onReceiveLayoutMessage(event: MessageEvent): void {
    let { data } = event;

    if ((data as LayoutMessage).type) {
      data = data as LayoutMessage;

      const { payload, type } = data;

      if (type === LayoutMessageType.LAYOUT_MESSAGE_TYPE_NAVIGATE) {
        // TODO
      } else if (type === LayoutMessageType.LAYOUT_MESSAGE_FINISH_DESIGN) {
        this.showNavigationModal(true);
      }
    }
  }

  /**
   * Processes messages from FPM
   *
   * @param event
   * @returns
   */
  onReceiveFPMMessage(event: MessageEvent): void {
    let { data } = event;
    if ((data as FPMMessage).type) {
      data = data as FPMMessage;

      const { payload, type } = data;

      if (type === FPMMessageType.FPM_MESSAGE_TYPE_UPDATE_AREAS) {
        if ((payload as AreaMessagePayload).areas.length > 0) {
          const { weekpart, totalCirculation, price } = payload;
          let selectedClientLocations = [] as ClientLocation[];
          let areas = [] as Area[];

          if ((payload.areas as Area[])[0].areaKey) {
            areas = payload.areas as Area[];
          } else if (
            ((payload as AreaMessagePayload).areas as ClientLocation[])[0].id
          ) {
            selectedClientLocations = (payload.areas as ClientLocation[]).filter(
              (location, index, self) =>
                index ===
                self.findIndex((pLocation) => pLocation.id === location.id)
            );
          }

          this.setState({
            price,
            areas,
            selectedClientLocations,
            weekpart,
            totalCirculation,
          });
        } else {
          const { weekpart, totalCirculation, price } = payload;
          this.setState({
            price,
            areas: [],
            selectedClientLocations: [],
            weekpart,
            totalCirculation,
          });
        }
      } else if (type === FPMMessageType.FPM_MESSAGE_TYPE_UPDATE_LOCALITIES) {
        if ((data.payload as LocalityMessagePayload).area) {
          const { areas, selectedClientLocations } = this.state;
          const {
            area,
            totalCirculation,
          } = data.payload as LocalityMessagePayload;

          if (!area) return;

          const fArea = this.findArea(area);

          if (!fArea) return;

          fArea.localities = area.localities;
          fArea.circulation = area.circulation;

          this.setState({
            areas,
            selectedClientLocations,
            totalCirculation: totalCirculation ?? 0,
          });
        }
      } else if (type === FPMMessageType.FPM_MESSAGE_TYPE_ADD_CLIENT_LOCATION) {
        if ((data.payload as ClientLocation).id) {
          const { selectedClientLocations } = this.state;

          // selectedClientLocations.push(data.payload as ClientLocation);
          this.setState({
            selectedClientLocations: [
              ...selectedClientLocations,
              ...[data.payload as ClientLocation],
            ],
          });
        }
      } else if (
        type === FPMMessageType.FPM_MESSAGE_TYPE_REMOVE_CLIENT_LOCATION
      ) {
        if (data.payload)
          this.removeClientLocationFromSelection(
            data.payload as ClientLocation
          );
      } else if (type === FPMMessageType.FPM_MESSAGE_TYPE_UPDATE_PRICE) {
        if (data.payload) this.setPrice(data.payload);
      } else if (
        type === FPMMessageType.FPM_MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION
      ) {
        if (data.payload) {
          const { selectedClientLocations } = this.state;
          const hPayload = data.payload as HideClientLocationSelectionPayload;

          const fClientLocation = find(selectedClientLocations, {
            id: hPayload.clientLocationId,
          });

          if (fClientLocation) {
            fClientLocation.show = hPayload.show;

            this.setState({ selectedClientLocations });
          }
        }
      }
    }
  }

  /**
   * Get the total circulation of the current selection
   *
   * @returns
   */
  getTotalCirculation(): number {
    const { areas, clientLocationMode, selectedClientLocations } = this.state;
    if (clientLocationMode)
      return getTotalCirculationClientLocations(selectedClientLocations);
    return getTotalCirculationAreas(areas);
  }

  /**
   * Get all the users clients
   */
  async getUserClients(): Promise<void> {
    const { user } = this.state;

    if (user) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENTS);
      const clients = (await getClients(user.email)) as Client[];

      clients.sort((a, b) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
        return 0;
      });

      if (clients) {
        this.setState({ clients }, () => {
          this.changeSelectedClient(clients[0]);
          this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENTS);
        });
      } else this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENTS);
    }
  }

  /**
   * Get all client location of the currently selected user
   *
   * @returns
   */
  async getClientLocations(): Promise<void> {
    const { selectedClient } = this.state;

    if (!selectedClient) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

    const clientLocations = (await getClientLocations(
      selectedClient.uuid
    )) as ClientLocation[];

    if (!Number.isNaN(+clientLocations)) {
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);
      return;
    }

    selectedClient.clientLocations = clientLocations;

    this.setState(
      {
        selectedClient,
      },
      () =>
        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS
        )
    );
  }

  /**
   * Get the price of the current selection
   *
   * @param printPerClientLocation
   * @param additionalOptions
   */
  async getPrice(
    printPerClientLocation: boolean = false,
    additionalOptions?: AdditionalOptions
  ): Promise<void> {
    const {
      areas,
      selectedClientLocations,
      weekpart,
      selectedProduct,
    } = this.state;

    const clientLocationsSend =
      selectedClientLocations.length > 0
        ? await Promise.all(
            getClientLocationsSend(
              selectedClientLocations,
              [],
              [],
              printPerClientLocation
            )
          )
        : ([
            {
              id: -1,
              areas: getAreasSend(areas),
            },
          ] as ClientLocationSendFormat[]);

    const { price, clientLocationPrices } = (await getTotalPrice(
      clientLocationsSend,
      weekpart,
      printPerClientLocation,
      selectedProduct,
      additionalOptions
    )) as PriceResult;

    selectedClientLocations.forEach(
      // eslint-disable-next-line no-return-assign
      (clientLocation) =>
        (clientLocation.price = clientLocationPrices?.find(
          (clientLocationPrice: Price) =>
            clientLocationPrice.id === clientLocation.id
        ))
    );

    this.setState({ price, selectedClientLocations });
  }

  /**
   * Set the selecte product
   *
   * @param selectedProduct
   * @returns
   */
  setSelectedProduct(selectedProduct?: Product): void {
    if (!selectedProduct) return;

    this.setState({ selectedProduct }, () => changeProduct(selectedProduct));
  }

  /**
   * Set the price of the current selection
   *
   * @param price
   */
  setPrice(price: TotalPrice): void {
    this.setState({ price });
  }

  /**
   * Set the currently loggedin user
   *
   * @param user
   */
  setUser(user: User): void {
    const { cookies, history } = this.props;

    if (user.id) {
      this.setState(
        {
          user,
          clientLocationMode: true,
        },
        () => {
          cookies.set(COOKIE_USER_NAME, user);
          this.getUserClients();

          history.push(ROUTER_PATH_HOME);
        }
      );
    }
  }

  /**
   * Find a given area inside the selected
   * client locations array
   *
   * @param area
   * @returns
   */
  findArea(area: Area): Area | undefined {
    const { selectedClientLocations, areas, clientLocationMode } = this.state;
    let fArea: Area | undefined;
    if (clientLocationMode) {
      const clientLocation = find(selectedClientLocations, {
        id: area.clientLocationId,
      });
      if (!clientLocation) return fArea;

      fArea = find(clientLocation.areas, { areaKey: area.areaKey });

      if (!fArea) {
        forEach(clientLocation.areas, (pArea) => {
          fArea = find(pArea.additionalAreas, { areaKey: area.areaKey });
          if (fArea) return false;
          return true;
        });
      }
    } else {
      fArea = find(areas, { areaKey: area.areaKey });

      if (!fArea) {
        forEach(areas, (pArea) => {
          fArea = find(pArea.additionalAreas, { areaKey: area.areaKey });
          if (fArea) return false;
          return true;
        });
      }
    }

    return fArea;
  }

  /**
   * Change the password of the currently logged in user
   *
   * @param oldPassword
   * @param newPassword
   * @returns
   */
  changePassword(oldPassword: string, newPassword: string): void {
    const { user } = this.state;

    if (!user) return;

    putChangePassword(user.email, oldPassword, newPassword);
  }

  /**
   * Change the selected client
   *
   * @param selectedClient
   * @returns
   */
  async changeSelectedClient(
    selectedClient: Client | undefined
  ): Promise<void> {
    if (!selectedClient) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
    const client = (await getClientData(selectedClient.uuid)) as Client;

    if (Number.isNaN(+client)) {
      this.setState({ selectedClient: client, price: undefined }, () => {
        this.changeMapIFrameURL();
        this.changeLayoutIFrameURL();
        this.setSelectedProduct(head(client.products));
        this.getClientLocations();

        if (config.complaintUrl) this.changeComplaintIFrameURL();

        const { history } = this.props;

        history.push(ROUTER_PATH_HOME);
      });
    }

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
  }

  /**
   * Change the map iframe url
   */
  changeMapIFrameURL(): void {
    const { user, selectedClient } = this.state;
    const { defaultWeekpart } = config;

    const mapIFrameURL = `${config.mapUrl}?${PARAM_PARENTORIGIN}=${
      // const iFrameURL = `http://10.54.220.88:3006?${PARAM_PARENTORIGIN}=${
      window.location.origin
    }${selectedClient ? `&${PARAM_CLIENT_ID}=${selectedClient.uuid}` : ''}${
      user
        ? `&${PARAM_CLIENT_LOCATION_MODE}=1&${PARAM_EMAIL}=${user.email}`
        : ''
    }&${PARAM_WEEKPART}=${
      selectedClient?.weekparts[0] ?? defaultWeekpart
    }&TIME=${new Date().getTime() / 100}`;

    this.setState({
      mapIFrameURL,
      areas: [] as Area[],
      selectedClientLocations: [] as ClientLocation[],
    });
  }

  /**
   * Change the layoutdesign iframe url
   *
   * @param target
   */
  changeLayoutIFrameURL(target?: LayoutMessageNavType): void {
    const { user, selectedClient } = this.state;

    let layoutIFrameURL = '';

    if (selectedClient && user) {
      const { layoutUrl } = config;

      layoutIFrameURL = `${layoutUrl}?${PARAM_PARENTORIGIN}=${
        window.location.origin
      }${
        target ? `&${PARAM_INIT_TARGET}=${target}` : ''
      }${`&${PARAM_CLIENT_ID}=${selectedClient.uuid}`}${
        user.email ? `&${PARAM_EMAIL}=${user.email}` : ''
      }&TIME=${new Date().getTime() / 100}`;
    }

    this.setState({
      layoutIFrameURL,
    });
  }

  /**
   * Change the complaint iframe url
   *
   */
  changeComplaintIFrameURL(): void {
    const { user, selectedClient } = this.state;

    let complaintIFrameURL = '';

    if (selectedClient && user) {
      const { complaintUrl } = config;

      complaintIFrameURL = `${complaintUrl}?${PARAM_PARENTORIGIN}=${
        window.location.origin
      }${`&${PARAM_CLIENT_ID}=${selectedClient.uuid}`}${
        user.email ? `&${PARAM_EMAIL}=${user.email}` : ''
      }&TIME=${new Date().getTime() / 100}`;
    }

    this.setState({
      complaintIFrameURL,
    });
  }

  /**
   * Logout the currently logged in user
   */
  logoutUser(): void {
    this.setState(
      {
        user: undefined,
        clients: undefined,
        selectedClient: undefined,
        clientLocationMode: false,
        selectedProduct: DEFAULT_PRODUCTS[0],
        price: undefined,
      },
      () => {
        const { cookies, history } = this.props;

        cookies.remove(COOKIE_USER_NAME);
        this.changeMapIFrameURL();
        this.changeLayoutIFrameURL();

        if (config.complaintUrl) this.changeComplaintIFrameURL();

        history.push(ROUTER_PATH_HOME);
      }
    );
  }

  /**
   * Remove a client location and its areas from
   * the current selectio
   *
   * @param clientLocation
   */
  removeClientLocationFromSelection(clientLocation: ClientLocation): void {
    const { selectedClientLocations } = this.state;

    remove(
      selectedClientLocations,
      (selectedClientLocation) =>
        selectedClientLocation.id === clientLocation.id
    );

    this.setState({ selectedClientLocations }, () =>
      this.setState({ totalCirculation: this.getTotalCirculation() })
    );
  }

  /**
   * Remove the whole selection
   */
  resetAreaSelection(): void {
    this.setState({
      areas: [],
      selectedClientLocations: [],
    });
  }

  /**
   * Shows/hides the login modal
   *
   * @param showLoginModal
   */
  showLoginModal(showLoginModal: boolean): void {
    this.setState({ showLoginModal });
  }

  /**
   * Shows/hides the password modal
   *
   * @param showChangePasswordModal
   */
  showChangePasswordModal(showChangePasswordModal: boolean): void {
    this.setState({ showChangePasswordModal });
  }

  /**
   * Shows/hides the order modal
   *
   * @param showOrderModal
   */
  showOrderModal(showOrderModal: boolean): void {
    this.setState({ showOrderModal });
  }

  /**
   * Shows/hides the confirmation modal
   *
   * @param showConfirmationModal
   * @param title
   * @param content
   * @param callback
   * @returns
   */
  showConfirmationModal(
    showConfirmationModal: boolean,
    title: string,
    content: string,
    callback: () => any
  ): void {
    const { current } = this.confirmationModalRef;

    if (current === null) return;

    current.setModalContents(title, content, callback);

    this.setState({
      showConfirmationModal,
    });
  }

  /**
   * Shows/hides the response modal
   *
   * @param showResponseModal
   * @param title
   * @param content
   * @returns
   */
  showResponseModal(
    showResponseModal: boolean,
    title: string,
    content: string
  ): void {
    const { current } = this.responseModalRef;

    if (current === null) return;

    current.setModalContents(title, content);

    this.setState({
      showResponseModal,
    });
  }

  /**
   * Shows/Hides the main menu
   *
   * @param showMainMenu
   */
  showMainMenu(showMainMenu: boolean): void {
    this.setState({ showMainMenu });
  }

  /**
   * Shows/Hides the navigation modal
   *
   * @param showNavigationModal
   */
  showNavigationModal(showNavigationModal: boolean): void {
    this.setState({ showNavigationModal });
  }

  /**
   * Submit the change password api call
   *
   * @param oldPassword
   * @param newPassword
   * @param callback
   */
  async submitChangePassword(
    oldPassword: string,
    newPassword: string,
    callback?: Function
  ): Promise<void> {
    const { user } = this.state;

    if (user) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_PASSWORD);
      const status = await putChangePassword(
        user.email,
        oldPassword,
        newPassword
      );

      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_PASSWORD);
      if (callback) callback(status);
    }
  }

  /**
   * Submit the login api call
   *
   * @param email
   * @param password
   * @param callback
   */
  async submitLogin(
    email: string,
    password: string,
    callback?: Function
  ): Promise<void> {
    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOGIN);

    const user = (await getLogin(email, password)) as User;

    if (user.id) this.setUser(user);

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOGIN);
    if (callback) callback(user.id ? 200 : 401);
  }

  /**
   * Submit the login via token api call
   *
   * @returns
   */
  async submitLoginViaToken(): Promise<void> {
    const { token } = this.props;

    if (!token) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_LOGIN);

    this.logoutUser();

    const user = (await getAuthByToken(token)) as User;

    if (user.id) this.setUser(user);

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_LOGIN);
  }

  /**
   * Submit the order api call
   *
   * @param actionName
   * @param distributionDate
   * @param email
   * @param extraCopies
   * @param lastname
   * @param masterFiles
   * @param billingType
   * @param phone
   * @param prename
   * @param additionalOptions
   * @param dateType
   * @param printPerClientLocation
   * @param billingClientLocation
   * @param company
   * @param message
   * @param salutation
   */
  async submitOfferRequest(
    actionName: string,
    distributionDate: Moment | null,
    email: string,
    extraCopies: ExtraCopy[],
    lastname: string,
    masterFiles: (MasterFile | LayoutSelection)[],
    billingType: BillingType,
    phone: string,
    prename: string,
    additionalOptions: AdditionalOptions,
    dateType: DateType,
    printPerClientLocation: boolean,
    billingClientLocation?: ClientLocation,
    company?: string,
    message?: string,
    salutation?: Salutation
  ): Promise<void> {
    const {
      areas,
      selectedClientLocations,
      clientLocationMode,
      weekpart,
      selectedClient,
      user,
      selectedProduct,
    } = this.state;

    let locations = [] as ClientLocationSendFormat[];

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_SEND_OFFER_ORDER);

    if (clientLocationMode)
      locations = await Promise.all(
        getClientLocationsSend(
          selectedClientLocations,
          masterFiles,
          extraCopies,
          printPerClientLocation
        )
      ).then((result) => result as ClientLocationSendFormat[]);
    else {
      locations = [
        {
          id: -1,
          areas: getAreasSend(areas),
          extraCopies: extraCopies[0]?.extraCopies ?? 0,
        },
      ] as ClientLocationSendFormat[];
    }

    const masterFileObj = masterFiles.find(
      (pMasterFile) => pMasterFile.clientLocationId === -1
    );
    let masterFile;
    let isFile = false;

    if ((masterFileObj as MasterFile)?.masterFile) {
      masterFile = (await getBas64FromFile(
        (masterFileObj as MasterFile)?.masterFile
      )) as string;
      isFile = true;
    } else if ((masterFileObj as LayoutSelection)?.layoutId)
      masterFile = (masterFileObj as LayoutSelection).layoutId;

    let payload = {
      ...(selectedClient ? { clientId: selectedClient.id } : {}),
      ...(user ? { userId: user.id } : {}),
      actionName,
      locations,
      company,
      email,
      forname: prename,
      message,
      phone,
      productId: selectedProduct.id,
      salutation,
      surename: lastname,
      billingClientLocation,
      billingType,
      ...(distributionDate
        ? { distributionDay: distributionDate.format('YYYY-MM-DD') }
        : {}),
      weekpart,
      additionalOptions,
      dateType,
      ...(!printPerClientLocation
        ? isFile
          ? { masterFile }
          : { layoutId: masterFile }
        : {}),
    } as RequestPayload;

    let response: any;
    if (
      selectedClient &&
      selectedClient?.transmissionType === TRANSMISSION_TYPE_ORDER
    ) {
      payload = { ...payload, ...{ order_request_id: -1 } };
      response = await postOrderRequest(payload);
    } // else {
    //   payload = { ...payload, ...{ offer_request_id: -1 } };
    //   response = await postOfferRequest(payload);
    // }

    if (response.status < 300) {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_SUCCESS_TITLE,
        RESPONSE_MODAL_SUCCESS_CONTENT
      );

      addOrder({
        ...payload,
        ...{ id: response.data },
        ...{ additionalOptions: {} },
      });
    } else
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT(response.status)
      );
    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_SEND_OFFER_ORDER);
  }

  /**
   * Shows/hides the loading overlay
   * @param loading
   * @param requestIdentifier
   */
  enableLoadingOverlay(loading: boolean, requestIdentifier: string): void {
    const { pendingRequests } = this.state;

    let nPendingRequests = [] as string[];

    if (loading) nPendingRequests = [requestIdentifier, ...pendingRequests];
    else {
      const index = pendingRequests.indexOf(requestIdentifier);
      if (index < 0) nPendingRequests = pendingRequests;
      else {
        nPendingRequests = [
          ...pendingRequests.slice(0, index),
          ...pendingRequests.slice(index + 1),
        ];
      }
    }

    this.setState({
      pendingRequests: nPendingRequests,
      isLoading: nPendingRequests.length > 0,
    });
  }

  /**
   * Navigate to a subsite
   *
   * @param target
   */
  navigateTo(target: FinishActionNavType): void {
    const { history } = this.props;

    history.push(target);
  }

  render(): JSX.Element {
    const {
      user,
      clients,
      mapIFrameURL,
      layoutIFrameURL,
      complaintIFrameURL,
      selectedClient,
      showLoginModal,
      showOrderModal,
      showChangePasswordModal,
      showConfirmationModal,
      showResponseModal,
      showNavigationModal,
      showMainMenu,
      areas,
      selectedClientLocations,
      totalCirculation,
      weekpart,
      isLoading,
      clientLocationMode,
      selectedProduct,
      price,
    } = this.state;

    return (
      <div className="app-container">
        {isLoading && (
          <LoadingOverlay
            loadingTitle={LOADING_PLEASE_WAIT}
            loadingSubtitle={LOADING_PROCESS_REQUEST}
          />
        )}
        <LoginModal
          show={showLoginModal}
          showModal={this.showLoginModal}
          submitLogin={this.submitLogin}
        />
        <ChangePasswordModal
          show={showChangePasswordModal}
          showModal={this.showChangePasswordModal}
          submitNewPassword={this.submitChangePassword}
        />
        <ConfirmationModal
          ref={this.confirmationModalRef}
          show={showConfirmationModal}
          showModal={this.showConfirmationModal}
        />
        <ResponseModal
          ref={this.responseModalRef}
          show={showResponseModal}
          showModal={this.showResponseModal}
        />
        <NavigationModal
          show={showNavigationModal}
          showModal={this.showNavigationModal}
          navigateTo={this.navigateTo}
        />
        <OrderModal
          show={showOrderModal}
          selectedClientLocations={selectedClientLocations}
          areas={areas}
          client={selectedClient}
          user={user}
          weekpart={weekpart}
          product={selectedProduct}
          price={price}
          getPrice={this.getPrice}
          showModal={this.showOrderModal}
          submitOrder={this.submitOfferRequest}
        />
        <HeaderContainer
          user={user}
          clients={clients}
          selectedClient={selectedClient}
          menuShown={showMainMenu}
          changeSelectedClient={this.changeSelectedClient}
          showLoginModal={this.showLoginModal}
          showMainMenu={this.showMainMenu}
        />
        {user && (
          <MainMenu
            show={showMainMenu}
            modules={selectedClient?.modules}
            showMenu={this.showMainMenu}
            logoutUser={this.logoutUser}
            showChangePassword={this.showChangePasswordModal}
            changeLayoutIFrameURL={this.changeLayoutIFrameURL}
          />
        )}
        <div className="app-content-container">
          <Switch>
            {selectedClient?.modules?.find(
              (module) => module.type === 'LAYOUTDESIGN'
            )?.enabled && (
              <Route path={ROUTER_PATH_LAYOUT}>
                <LayoutContainer
                  client={selectedClient}
                  user={user}
                  iFrameURL={layoutIFrameURL}
                  enableLoadingOverlay={this.enableLoadingOverlay}
                />
              </Route>
            )}
            <Route path={ROUTER_PATH_DISTRIBUTION}>
              <DistributionPlaningContainer
                iFrameURL={mapIFrameURL}
                user={user}
                selectedClient={selectedClient}
                selectedProduct={selectedProduct}
                price={price}
                areas={areas}
                totalCirculation={totalCirculation}
                selectedClientLocations={selectedClientLocations}
                weekpart={weekpart}
                clientLocationMode={clientLocationMode}
                showOrderRequestModal={this.showOrderModal}
                setSelectedProduct={this.setSelectedProduct}
                resetAreaSelection={this.resetAreaSelection}
              />
            </Route>
            {config.complaintUrl &&
              selectedClient?.modules?.find(
                (module) => module.type === 'COMPLAINT'
              )?.enabled && (
                <Route path={ROUTER_PATH_COMPLAINT}>
                  <ComplaintContainer
                    iFrameURL={complaintIFrameURL}
                    enableLoadingOverlay={this.enableLoadingOverlay}
                  />
                </Route>
              )}
            <Route path={ROUTER_PATH_HOME} exact>
              {user ? (
                <StepSelectionContainer modules={selectedClient?.modules} />
              ) : (
                <Redirect to={ROUTER_PATH_DISTRIBUTION} />
              )}
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(App));

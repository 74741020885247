// Contains all constant values used in the app

import { Theme } from 'react-select';
// eslint-disable-next-line import/no-cycle
import { Module, Product } from '../@types/Data.d';

export const COOKIE_USER_NAME = 'user';

export const ROUTER_PATH_HOME = '/';
export const ROUTER_PATH_DISTRIBUTION = '/distribution';
export const ROUTER_PATH_COMPLAINT = '/complaint';
export const ROUTER_PATH_LAYOUT = '/layout';

export const selectPickerTheme = (theme: Theme): Theme =>
  ({
    borderRadius: 0, // theme.borderRadius,
    colors: {
      ...theme.colors,
      // danger: '#DE350B',
      // dangerLight: '#FFBDAD',
      neutral0: 'rgb(252, 252, 252)', // bg
      neutral5: '#e9ecef', // bg diabled
      neutral10: 'rgb(211, 211, 211)', // border disabled
      // neutral20: 'rgb(0, 0, 0)', // border
      neutral30: process.env.REACT_APP_SCSS_TERTIARY ?? 'rgb(63, 175, 212)', // hover
      // neutral40: 'rgb(83, 164, 198)', // icon hover
      neutral50: 'rgb(116, 116, 116)', // hint color
      neutral60: 'rgb(116, 116, 116)', // icon not focused
      neutral70: 'rgb(116, 116, 116)',
      neutral80: 'rgb(91, 91, 91)', // drop icon press & text
      neutral90: 'rgb(116, 116, 116)',
      primary: process.env.REACT_APP_SCSS_PRIMARY ?? 'rgb(63, 175, 212)', // border focus,
      primary25: process.env.REACT_APP_SCSS_PRIMARY ?? 'rgb(63, 175, 212)', // item hover
      primary50: process.env.REACT_APP_SCSS_TERTIARY ?? 'rgb(83, 164, 198)', // item press
      // primary75: 'pink',
      neutral40: 'rgb(255, 255, 255)', // item press
    },
    spacing: theme.spacing,
  } as Theme);

export const TRANSMISSION_TYPE_ORDER = 'ORDER';
export const TRANSMISSION_TYPE_OFFER = 'OFFER';

export const WEEKPART_WEEKEND: string = 'WEEKEND';
export const WEEKPART_MIDWEEK: string = 'MIDWEEK';
export const WEEKPART_BEST: string = 'BEST';

export const SALUTATION_MALE = 'Herr';
export const SALUTATION_FEMALE = 'Frau';

export const SALUTATION_ARRAY = [
  { value: SALUTATION_MALE, label: SALUTATION_MALE },
  { value: SALUTATION_FEMALE, label: SALUTATION_FEMALE },
];

export const EXTRA_COPIES_ARRAY = [
  { value: 0, label: 'Keine' },
  { value: 100, label: '100 Stk.' },
  { value: 250, label: '250 Stk.' },
];

export const DEFAULT_PRODUCT_DISTRIBUTION = 'Verteilung';
export const DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION = 'Druck & Verteilung';

export const DEFAULT_PRODUCTS = [
  {
    name: DEFAULT_PRODUCT_DISTRIBUTION,
    printDocRequired: false,
    id: -1,
  } as Product,
  {
    name: DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION,
    printDocRequired: true,
    id: -2,
  } as Product,
];

export const REQUEST_IDENTIFIER_GET_CLIENTS = 'GET_CLIENTS';
export const REQUEST_IDENTIFIER_GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS = 'GET_CLIENT_LOCATIONS';
export const REQUEST_IDENTIFIER_CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REQUEST_IDENTIFIER_LOGIN = 'LOGIN';
export const REQUEST_IDENTIFIER_SEND_OFFER_ORDER = 'SEND_OFFER_ORDER';
export const REQUEST_IDENTIFIER_LOAD_LAYOUT_IFRAME = 'LOAD_LAYOUT_IFRAME';
export const REQUEST_IDENTIFIER_LOAD_COMPLAINT_IFRAME = 'LOAD_COMPLAINT_IFRAME';

export const DUMMY_MODULES = [
  {
    id: 1,
    type: 'DISTRIBUTION',
    title: 'Verteilung',
    enabled: true,
  },
  {
    id: 2,
    type: 'LAYOUTDESIGN',
    title: 'Layoutdesign',
    enabled: true,
  },
  {
    id: 3,
    type: 'DIALOG',
    title: 'Dialogmarketing',
    enabled: false,
  },
  { id: 4, type: 'BILBOARD', title: 'Plakatwerbung', enabled: false },
  {
    id: 5,
    type: 'ADDITIONALOPTIONS',
    title: 'Zusätzliche Optionen',
    enabled: true,
  },
  {
    id: 6,
    type: 'COMPLAINT',
    title: 'Reklamationen',
    enabled: true,
  },
] as Module[];

// Contains all labels used in the app

// eslint-disable-next-line import/no-cycle
import { WEEKPART_BEST, WEEKPART_WEEKEND, WEEKPART_MIDWEEK } from './constants';

import { Weekpart, DistributionType } from '../@types/Data.d';

import { priceString } from '../util/convertUtil';

export const UNIT_KILOMETERS = 'Kilometer';
export const UNIT_KILOMETERS_SHORT = 'km';
export const UNIT_WEEKS = 'Woche(n)';
export const UNIT_WEEKS_SHORT = 'Wo.';

export const CALENDAR_WEEK = 'Kalenderwoche';
export const CALENDAR_WEEK_SHORT = 'KW';

export const DASHBOARD_LAYOUT = 'Layoutgestaltung';
export const DASHBOARD_DISTRIBUTION = 'Verteilplanung';
export const DASHBOARD_COMPLAINT = 'Verteilanalyse';
export const DASHBOARD_HEADER_TEXT = 'Startseite';

export const LOADING_PLEASE_WAIT: string = 'Bitte warten';
export const LOADING_PROCESS_REQUEST: string =
  'Ihre Anfrage wird bearbeitet ...';

export const REQUIRED_FIELD_HINT = '* Pflichtfeld';

export const SALUTATION_MALE_LABEL = 'Herr';
export const SALUTATION_FEMALE_LABEL = 'Frau';

export const CIRCULATION_PIECES_SHORT: string = 'Stk.';
export const CIRCULATION_PIECES_LONG: string = 'Stück';
export const CIRCULATION_TITLE_TOTAL_SHORT: string = 'Ges';
export const CIRCULATION_TITLE_TOTAL_LONG: string = 'Verteilung Gesamt';
export const CIRCULATION_TITLE_WEEKEND_SHORT: string = 'WE';
export const CIRCULATION_TITLE_WEEKEND_LONG: string = 'Verteilung Wochenende';
export const CIRCULATION_TITLE_MIDWEEK_SHORT: string = 'WM';
export const CIRCULATION_TITLE_MIDWEEK_LONG: string = 'Verteilung Wochenmitte';
export const CIRCULATION_TITLE_BEST_SHORT: string = 'Optimal';
export const CIRCULATION_TITLE_BEST_LONG: string = 'Verteilung Optimal';
export const CIRCULATION_NO_DISTRIBUTION: string = 'Keine Verteilung';
export const CIRCULATION_INCLUDED: string = 'enthalten';

export const PRICE_CURRENCY_SHORT = '€';
export const PRICE_CURRENCY_SHORTER = 'EUR';
export const PRICE_CURRENCY = 'Euro';
export const PRICE_LABEL = 'Gesamtpreis:';
export const FORM_MISSING_VALUE = 'Dieses Feld muss ausgefüllt werden.';
export const NO_CLIENT_LOCATIONS_SELECTED = 'Keine Filialen ausgewählt.';
export const NO_AREAS_SELECTED = 'Keine Gebiete ausgwählt.';
export const NO_PHONE_NUMBER = 'Keine Telefonnummer angegeben.';

export const HEADER_USER_MENU_OPTION_CHANGE_PASS = 'Passwort ändern';
export const HEADER_USER_MENU_OPTION_LOGOUT = 'Logout';
export const HEADER_LOGIN_BUTTON = 'Anmelden';
export const HEADER_CLIENT_LABEL = 'Mandant:';
export const HEADER_CLIENT_PLACEHOLDER = 'Mandant wählen';
export const HEADER_PRODUCT_LABEL = 'Produkt:';
export const HEADER_PRODUCT_PLACEHOLDER = 'Produkt wählen';
export const HEADER_PRODUCT_INVALID = 'Bitte wählen Sie ein Produkt';

export const BUTTON_TITLE_CREATE: string = 'Erstellen';
export const BUTTON_TITLE_OK: string = 'Ok';
export const BUTTON_TITLE_CONFIRM: string = 'Bestätigen';
export const BUTTON_TITLE_ACCEPT: string = 'Übernehemen';
export const BUTTON_TITLE_ABORT: string = 'Abbrechen';
export const BUTTON_TITLE_IMPORT: string = 'Importieren';
export const BUTTON_TITLE_SUBMIT: string = 'Absenden';
export const BUTTON_TITLE_NEXT: string = 'Weiter';
export const BUTTON_TITLE_PREVOUS: string = 'Zurück';
export const BUTTON_TITLE_FINISH: string = 'Fertig';
export const BUTTON_TITLE_APPLY_TO_ALL: string = 'Übernehmen';

export const CHANGE_PASSWORD_MODAL_TITLE = 'Passwort ändern';
export const CHANGE_PASSWORD_MODAL_OLD_PASS_LABEL = 'Altes Password';
export const CHANGE_PASSWORD_MODAL_OLD_PASS_PLACEHOLDER = 'Altes Password';
export const CHANGE_PASSWORD_MODAL_OLD_PASS_INVALID =
  'Bitte geben Sie ihr altes Passwort ein';
export const CHANGE_PASSWORD_MODAL_NEW_PASS_LABEL = 'Neues Passwort';
export const CHANGE_PASSWORD_MODAL_NEW_PASS_PLACEHOLDER = 'Neues Passwort';
export const CHANGE_PASSWORD_MODAL_NEW_PASS_INVALID =
  'Bitte geben Sie ein neues Passwort ein';
export const CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_LABEL =
  'Neues Passwort bestätigen';
export const CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_PLACEHOLDER =
  'Neues Passwort bestätigen';
export const CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_INVALID =
  'Das einegebene Passwort stimmt nicht mit dem neuen Passwort überein';
export const CHANGE_PASSWORD_MODAL_FAILURE =
  'Das ändern des Passworts ist fehlgeschlagen';

export const LOGIN_MODAL_TITLE = 'Anmelden';
export const LOGIN_MODAL_EMAIL_TITLE = 'Email';
export const LOGIN_MODAL_EMAIL_PLACEHOLDER = 'Email eingeben ...';
export const LOGIN_MODAL_EMAIL_INVALID =
  'Bitte geben Sie eine gültige Email-Adresse ein';
export const LOGIN_MODAL_PASSWORD_TITLE = 'Passwort';
export const LOGIN_MODAL_PASSWORD_PLACEHOLDER = 'Passwort eingeben ...';
export const LOGIN_MODAL_PASSWORD_INVALID = 'Bitte geben Sie ihr Passwort ein';
export const LOGIN_MODAL_LOGIN_BUTTON = 'Anmelden';
export const LOGIN_MODAL_ABORT_BUTTON = 'Abbrechen';
export const LOGIN_MODAL_ABORT_FAILURE =
  'Die angegebenen Logindaten sind ungültig.';

export const LOCALITIES_MODAL_TITLE = (
  areaName: string,
  areaKey: string
): string => `Orsteile von ${areaKey}, ${areaName} an-/abwählen`;
export const LOCALITIES_TOOLTIP: string =
  'Es wurden ein oder merhere Ortsteile abgewählt.';

export const ADDITIONAL_AREA_TOLLTIP = (
  areaName: string,
  areaKey: string
): string => `Tourenabhängigkeit von ${areaKey}, ${areaName}.`;

export const OFFER_REQUEST_BUTTON_TITLE = 'Angebot anfragen';
export const ORDER_REQUEST_BUTTON_TITLE = 'Auftrag übermitteln';
export const REQUEST_BUTTON_TITLE = (isOrder: boolean): string =>
  `${isOrder ? ORDER_REQUEST_BUTTON_TITLE : OFFER_REQUEST_BUTTON_TITLE}`;

export const OFFER_MODAL_TITLE = (isOffer: boolean): string =>
  isOffer ? 'Angebot anfragen' : 'Bestellung aufgeben';

export const OFFER_MODAL_STEP_ACTION = 'Aktion';
export const OFFER_MODAL_STEP_EXTRAS = 'Extras';
export const OFFER_MODAL_STEP_CONTACT = 'Kontaktdaten';
export const OFFER_MODAL_STEP_PRINT = 'Druckdaten';
export const OFFER_MODAL_STEP_SUMMARY = 'Zusammenfassung';
export const OFFER_MODAL_STEP_FINISH = 'Absenden';

export const OFFER_MODAL_STEPS_TITLES = [
  OFFER_MODAL_STEP_ACTION,
  OFFER_MODAL_STEP_EXTRAS,
  OFFER_MODAL_STEP_CONTACT,
  OFFER_MODAL_STEP_PRINT,
  OFFER_MODAL_STEP_SUMMARY,
  OFFER_MODAL_STEP_FINISH,
];

export const OFFER_MODAL_EXTRAS_CLIENTLOCATION_TITLE = 'Filiale';
export const OFFER_MODAL_EXTRAS_START_DATE_TITLE = 'Startdatum';
export const OFFER_MODAL_EXTRAS_START_DATE_PLACEHOLDER = OFFER_MODAL_EXTRAS_START_DATE_TITLE;
export const OFFER_MODAL_EXTRAS_START_DATE_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_EXTRAS_RANGE_TITLE = 'Umkreis';
export const OFFER_MODAL_EXTRAS_RANGE_PLACEHOLDER = OFFER_MODAL_EXTRAS_RANGE_TITLE;
export const OFFER_MODAL_EXTRAS_DURATION_TITLE = 'Laufzeit';
export const OFFER_MODAL_EXTRAS_DURATION_PLACEHOLDER = OFFER_MODAL_EXTRAS_DURATION_TITLE;
export const OFFER_MODAL_EXTRAS_WEB_TITLE = 'Zieladresse (Web)';
export const OFFER_MODAL_EXTRAS_WEB_PLACEHOLDER = OFFER_MODAL_EXTRAS_WEB_TITLE;

export const OFFER_MODAL_ADDRESS_TITLE = 'Kontaktdaten';
export const OFFER_MODAL_ADDRESS_CONTACT_PERSON_TITLE = 'Ansprechpartner';
export const OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL = (
  isOffer: boolean
): string => `${isOffer ? 'Angebot' : 'Bestellung'} pro Filiale`;
export const OFFER_MODAL_ADDRESS_BILLING_TYPE_PLACEHOLDER = (
  isOffer: boolean
): string => OFFER_MODAL_ADDRESS_BILLING_TYPE_LABEL(isOffer);
export const OFFER_MODAL_ADDRESS_BILLING_TYPE_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL = (
  isOffer: boolean
): string => `Filiale für  ${isOffer ? 'das Angebot' : 'die Bestellung'}*`;
export const OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_PLACEHOLDER = (
  isOffer: boolean
): string => OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_LABEL(isOffer);
export const OFFER_MODAL_ADDRESS_BILLING_SUSIDIARY_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_COMPANY_LABEL = 'Firma*';
export const OFFER_MODAL_ADDRESS_COMPANY_PLACEHOLDER = OFFER_MODAL_ADDRESS_COMPANY_LABEL;
export const OFFER_MODAL_ADDRESS_COMPANY_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_SALUTATION_LABEL = 'Anrede*';
export const OFFER_MODAL_ADDRESS_SALUTATION_PLACEHOLDER = OFFER_MODAL_ADDRESS_SALUTATION_LABEL;
export const OFFER_MODAL_ADDRESS_SALUTATION_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_PRENAME_LABEL = 'Vorname*';
export const OFFER_MODAL_ADDRESS_PRENAME_PLACEHOLDER = OFFER_MODAL_ADDRESS_PRENAME_LABEL;
export const OFFER_MODAL_ADDRESS_PRENAME_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_LASTNAME_LABEL = 'Nachname*';
export const OFFER_MODAL_ADDRESS_LASTNAME_PLACEHOLDER = OFFER_MODAL_ADDRESS_LASTNAME_LABEL;
export const OFFER_MODAL_ADDRESS_LASTNAME_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_PHONE_LABEL = 'Telefon*';
export const OFFER_MODAL_ADDRESS_PHONE_PLACEHOLDER = OFFER_MODAL_ADDRESS_PHONE_LABEL;
export const OFFER_MODAL_ADDRESS_PHONE_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ADDRESS_EMAIL_LABEL = 'Email*';
export const OFFER_MODAL_ADDRESS_EMAIL_PLACEHOLDER = OFFER_MODAL_ADDRESS_EMAIL_LABEL;
export const OFFER_MODAL_ADDRESS_EMAIL_INVALID = FORM_MISSING_VALUE;

export const OFFER_MODAL_ACTION_TITLE = 'Aktion';
export const OFFER_MODAL_ACTION_ACTION_NAME_LABEL = 'Aktionsname';
export const OFFER_MODAL_ACTION_ACTION_NAME_PLACEHOLDER = OFFER_MODAL_ACTION_ACTION_NAME_LABEL;
export const OFFER_MODAL_ACTION_ACTION_NAME_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ACTION_DISTRIBUTION_DATE_LABEL = 'Verteiltag';
export const OFFER_MODAL_ACTION_DISTRIBUTION_DATE_PLACEHOLDER = OFFER_MODAL_ACTION_DISTRIBUTION_DATE_LABEL;
export const OFFER_MODAL_ACTION_DISTRIBUTION_DATE_INVALID = FORM_MISSING_VALUE;
export const OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_LABEL =
  'Verteilaktion';
export const OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_PLACEHOLDER = OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_LABEL;
export const OFFER_MODAL_ACTION_DISTRIBUTION_APPOINTMENT_INVALID = FORM_MISSING_VALUE;

export const OFFER_MODAL_PRINT_MASTER_TITLE = 'Druckvorlagen';
export const OFFER_MODAL_PRINT_MASTER_PER_CLIENT_LOCATION_LABEL =
  'Druckvorlage pro Filiale';
export const OFFER_MODAL_PRINT_MASTER_FILE_LABEL = 'Druckvorlage';
export const OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL = 'Durchsuchen';

export const OFFER_MODAL_PRINT_USE_LAYOUT_LABEL = 'Layout';

export const OFFER_MODAL_PRINT_LAYOUT_SELECT_LABEL = 'Layout auswählen';
export const OFFER_MODAL_PRINT_LAYOUT_SELECT_PLACEHOLDER = OFFER_MODAL_PRINT_LAYOUT_SELECT_LABEL;

export const OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL = 'Auslageexemplare';
export const OFFER_MODAL_PRINT_EXTRA_COPIES_PER_CLIENT_LOCATION_LABEL =
  'Auslageexemplare pro Filiale';
export const OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL = 'Anzahl';
export const OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER = OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL;

export const OFFER_MODAL_SUMMARY_CONACT_TITLE = 'Kontaktdaten';
export const OFFER_MODAL_SUMMARY_BILLING_CLIENT_LOCATION_TITLE =
  'Rechungsadresse';
export const OFFER_MODAL_SUMMARY_BILLING_PER_CLIENT_LOCATION_TITLE =
  'Es wird jeweils eine Rechung über die gewählte Verteilung an die jeweilige Filiale versendet.';

export const OFFER_MODAL_SUMMARY_ORDER_DATA_TITLE = 'Auftragsdaten';

export const OFFER_MODAL_SUMMARY_ORDER_DATA_DISTRIBUTION_SUB_TITLE =
  'Verteilung';

export const OFFER_MODAL_SUMMARY_ORDER_DATA_CLIENT_LOCATION_LABEL = 'Filiale';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_LABEL =
  'Auslageexemplare';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_CIRCULATION_LABEL = 'Auflage';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_LABEL = 'Preis';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_INTERMEDIATE_LABEL =
  'Zwischensumme';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_LABEL = 'Gesamt';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_ON_REQUEST_LABEL =
  'Auf Anfrage.';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_TOTAL_LABEL = (
  price?: string
): string => `${price ?? priceString('0')} ${PRICE_CURRENCY_SHORT}`;
export const OFFER_MODAL_SUMMARY_ORDER_DATA_EXTRA_COPIES_TOTAL_LABEL = (
  extraCopies?: number
): string =>
  `${
    extraCopies
      ? `${extraCopies.toLocaleString()} ${CIRCULATION_PIECES_SHORT}`
      : 'Keine'
  }`;
export const OFFER_MODAL_SUMMARY_ORDER_DATA_PRICE_PER_1000_LABEL = (
  price?: string
): string =>
  ` (${
    price ?? priceString('0')
  } ${PRICE_CURRENCY_SHORT}/1000 ${CIRCULATION_PIECES_SHORT})`;
export const OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_LABEL = (
  circulation?: number
): string =>
  `${circulation?.toLocaleString() ?? 0} ${CIRCULATION_PIECES_SHORT}`;
export const OFFER_MODAL_SUMMARY_ORDER_DATA_TOTAL_CIRCULATION_ANNOTATION =
  ' (zzgl. der gesetzlichen MwSt.)';

export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_CLIENT_LOCATION_TITLE =
  'Filiale';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_START_DATE_TITLE =
  'Startdatum';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_TITLE = 'Umkreis';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_TITLE = 'Laufzeit';
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_TITLE = 'Preis';

export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_RANGE_LABEL = (
  range?: number
): string => (range ? `${range} ${UNIT_KILOMETERS_SHORT}` : 'Keine');
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_DURATION_LABEL = (
  duration?: number
): string => (duration ? `${duration} ${UNIT_WEEKS}` : 'Keine');
export const OFFER_MODAL_SUMMARY_ORDER_DATA_KAUF_DA_PRICE_LABEL = (
  price?: string
): string => `${price ?? priceString('0')} ${PRICE_CURRENCY_SHORT}`;

export const OFFER_MODAL_SEND_MESSAGE_TITLE =
  'Möchten Sie uns noch etwas wissen lassen?';
export const OFFER_MODAL_SEND_MESSAGE_LABEL = 'Nachricht';

export const OFFER_MODAL_SEND_PRIVACY_TITLE = 'Datenschutzerklärung';
export const OFFER_MODAL_SEND_PRIVACY_LABEL =
  'Einwilligung zur Datenverarbeitung*';
export const OFFER_MODAL_SEND_PRIVACY_INFO =
  'Um Ihnen die gewünschten Inhalte bereitzustellen, müssen wir Ihre persönlichen Daten speichern und verarbeiten. Wenn Sie damit einverstanden sind, dass wir Ihre persönlichen Daten für diesen Zweck speichern, aktivieren Sie bitte das Kontrollkästchen.';
export const OFFER_MODAL_SEND_PRIVACY_INVALID =
  'Bitte geben Sie ihre Einwilligung zur Datenverarbeitung.';

export const BONIAL_EXPLANATION =
  'Bonial ist mit den Plattformen kaufDA und MeinProspekt der führende Anbieter für lokale Angebotskommunikation des stationären Handels in Deutschland. Das Unternehmen mit Hauptsitz in Berlin inspiriert jeden Monat über 10 Millionen Nutzer und verbindet sie mit ihren Lieblingsgeschäften und Marken vor Ort. Werbepartner können lokale Angebote gezielt an potenzielle Käufer über das Internet und Mobiltelefone ausspielen.';

export const CIRCULATION_TITLE = (
  weekpart?: Weekpart,
  long?: boolean
): string => {
  if (weekpart === WEEKPART_MIDWEEK)
    return long
      ? CIRCULATION_TITLE_MIDWEEK_LONG
      : CIRCULATION_TITLE_MIDWEEK_SHORT;
  if (weekpart === WEEKPART_WEEKEND)
    return long
      ? CIRCULATION_TITLE_WEEKEND_LONG
      : CIRCULATION_TITLE_WEEKEND_SHORT;
  if (weekpart === WEEKPART_BEST)
    return long ? CIRCULATION_TITLE_BEST_LONG : CIRCULATION_TITLE_BEST_SHORT;

  return long ? CIRCULATION_TITLE_TOTAL_LONG : CIRCULATION_TITLE_TOTAL_SHORT;
};

export const CONFIRMATION_MODAL_TITLE_DELETE = (subject: string): string =>
  `${subject} wirklich löschen?`;
export const CONFIRMATION_MODAL_CONTENT_DELETE = (subject: string): string =>
  `Wollen Sie diese ${subject} wirklich löschen?`;

export const RESPONSE_MODAL_FAILURE_TITLE = 'Anfrage fehlgeschlagen';
export const RESPONSE_MODAL_FAILURE_CONTENT = (errorCode: number): string =>
  `Ihre Anfrage ist Fehlgeschlagen. Bitte überprüfen Sie ihre Eingaben und versuchen Sie es erneut. (Fehlercode: ${errorCode})`;
export const RESPONSE_MODAL_SUCCESS_TITLE = 'Anfrage erfolgreich';
export const RESPONSE_MODAL_SUCCESS_CONTENT =
  'Ihre Anfrage wurde erfolgreich versendet.';

export const NAVIGATION_MODAL_TITLE = 'Weiter zu';
export const NAVIGATION_MODAL_CONTENT =
  'Mit welchem Schritt wollen Sie nun fortfahren?';
export const NAVIGATION_MODAL_STAY = 'Hier bleiben';

export const MAIN_MENU_ITEM_LABEL_HOME = 'Startseite';
export const MAIN_MENU_ITEM_LABEL_LAYOUT = 'Layoutdesign';
export const MAIN_MENU_ITEM_LABEL_LAYOUT_NEW = 'Neuer Entwurf';
export const MAIN_MENU_ITEM_LABEL_LAYOUT_LOAD = 'Entwurf laden';
export const MAIN_MENU_ITEM_LABEL_DISTRIBUTION = 'Verteilung';
export const MAIN_MENU_ITEM_LABEL_COMPLAINT = 'Verteilanalyse';

export const TOOLTIP_AREA_SHOW_LOCALITIES = 'Ortsteile anzeigen';
export const TOOLTIP_AREA_REMOVE = 'Gebiet aus Auswahl entfernen';

export const TOOLTIP_CLIENT_LOCATION_REMOVE = 'Filiale aus Auswahl entfernen';
export const TOOLTIP_CLIENT_LOCATION_COLLAPSE_ALL = 'Alle Filialen einklappen';
export const TOOLTIP_CLIENT_LOCATION_EXPAND_ALL = 'Alle Filialen ausklappen';
export const TOOLTIP_CLIENT_LOCATION_REMOVE_ALL = 'Ganze Auswahl entfernen';

export const TOOLTIP_SELECTED_CLIENT_LOCATION_REMOVE =
  'Filiale aus Auswahl entfernen';
export const TOOLTIP_SELECTED_CLIENT_LOCATION_HIDE =
  'Filialeauswahl ausblenden';

export const NOT_FOUND_TITLE = '404 - Die gesuchte Seite wurde nicht gefunden';
export const NOT_FOUND_CONTENT =
  'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden. Vergewissern Sie sich, dass die URL richtig geschrieben wurde oder Sie gegebenenfalls eingeloggt sind.';
export const NOT_FOUND_GO_BACK = 'Zurück';

export const TOOLTIP_DISTRIBUTION_TYPE = (
  distributionType: DistributionType,
  distributionName?: string
): string =>
  `${
    distributionType === 'DIRECT' ? 'Direktverteilung' : 'Beilagenverteilung'
  }${
    distributionName && distributionType === 'INSERT'
      ? ` (${distributionName})`
      : ''
  }`;

export const PRICE_SUM_LABEL = (
  price?: string,
  pricePerThousand?: string
): string =>
  `${price ?? priceString('0')} ${PRICE_CURRENCY_SHORT} (${
    pricePerThousand ?? priceString('0')
  } ${PRICE_CURRENCY_SHORT}/${(1000).toLocaleString()} Stk.)`;

export const WARNING_NO_AREAS_SELECTED_TITLE = 'Keine Gebiete ausgewählt.';
export const WARNING_NO_AREAS_SELECTED_CONTENT =
  'Bitte wählen Sie mindestens ein Gebiet.';
